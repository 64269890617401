.mainContainer{
    height: calc(100vh - 60px);
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content{
    justify-content: center;
    align-items: center;
    width: 100%;
    
}

.formContainer{
    height: 100%;
    max-width: 80%;
    padding: 20px;
    
}

// @media screen and (min-width: 1080px) {
//     .content{
//         max-width: 30%;
//     }
// }
@import '../../index.scss';





.formContainer{
    height: 100%;
    width: 100%;
    
}

.content{
    padding-top: 10em;
    justify-content: center;
    align-items: center
}


@media screen and (min-width: 800px) {
}

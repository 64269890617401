@import '../../index.scss';

.header {
    background-color: var(--base-color);
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1;
    text-align: left;
    color: white;
    align-items: center;
    // padding-left: 1em;
    height: 60px;
}

@media screen and (min-width: 1080px) {
    .formContainer{
        max-width: 30%;
    }
}

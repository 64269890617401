

:root { 
  --base-color: #000000;
--background-color: #F7F7F7;
--secondary-color: #606470;
--accent-color: #93DEFF;
--text-color: white;
}

body {
  margin: 0;
  scrollbar-width: none; /* for Firefox */
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
  overflow: moz-scrollbars-none;
  -webkit-appearance: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  height: 0;
}